import React from 'react'

function Order() {
	return (
		<div>
			<p>Orders page!!</p>
		</div>
	)
}

export default Order
